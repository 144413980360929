<template>
    <el-main v-if="info">
        <div class="title">用户信息</div>
        <div class="row">
            <template v-if="info.openid">
                <el-image :src="info.avatar" class="avatar"></el-image>
                <div class="m50">{{info.nick_name}}</div>
            </template>
            <div class="m50">用户姓名：{{info.name}}</div>
            <div class="m50">手机号码：{{info.mobile}}</div>
            <div>注册时间：{{info.create_time|getDateformat}}</div>
        </div>
        <div class="title">预约信息</div>
        <div class="row">
            <el-image :src="info.service[0].images" class="avatar"></el-image>
            <div class="m50" style="max-width:200px;">{{info.service[0].service_name}}</div>
            <div class="m50">预约医生：{{info.staff.service_name}}</div>
            <div class="m50">开单人：{{info.oprator_name}}</div>
            <div>到店时间：{{info.realiy_time|getDateformat}}</div>
        </div>
        <div class="title">诊断信息</div>
        <el-form  :model="o_form" label-width="120px" label-position="left" style="margin-top:20px;">
            <el-form-item label="就诊类型：">
                <div class="row" style="padding:0;height: 40px;">
                    <el-radio-group v-model="form.type" style="margin-right:20px;" :disabled="is_look">
                        <el-radio :label="1">初诊</el-radio>
                        <el-radio :label="2">复诊</el-radio>
                    </el-radio-group>
                    <div  v-if="form.type==2">
                        第 <el-input v-model="form.times" style="width:80px;margin: 0 10px;" :disabled="is_look"></el-input> 次
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="就诊需求：">
                <el-select v-model="form.needs" multiple filterable placeholder="请输入关键词"
                @visible-change="getChoice($event,1)" class="input_class" clearable :disabled="is_look">
                    <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.title">
                    </el-option>
                    <el-option label="其他" :value="0"></el-option>
                </el-select><br>
                <el-input class="input_class" placeholder="请添加其他" v-model="o_form.needs" v-if="form.needs.indexOf(0)!=-1" style="margin-top:10px;"></el-input>
            </el-form-item>
             <el-form-item label="既往史：">
                <el-select v-model="form.old" multiple filterable placeholder="请输入关键词"
                @visible-change="getChoice($event,2)" class="input_class" clearable :disabled="is_look">
                    <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.title">
                    </el-option>
                    <el-option label="其他疾病" :value="0"></el-option>
                </el-select><br>
                <el-input class="input_class" placeholder="请添加其他疾病" v-model="o_form.old"  v-if="form.old.indexOf(0)!=-1" style="margin-top:10px;"></el-input>
            </el-form-item>
             <el-form-item label="过敏史：">
                <el-select v-model="form.allergy" multiple filterable placeholder="请输入关键词"
                @visible-change="getChoice($event,3)" class="input_class" clearable :disabled="is_look">
                    <el-option-group label="药物">
                        <el-option
                            v-for="item in options.filter(v=>v.allergy_type==1)"
                            :key="item.id"
                            :label="item.title"
                            :value="item.title">
                        </el-option>
                        <el-option label="其他药物" :value="-1"></el-option>
                    </el-option-group>
                     <el-option-group label="食物">
                        <el-option
                            v-for="item in options.filter(v=>v.allergy_type==2)"
                            :key="item.id"
                            :label="item.title"
                            :value="item.title">
                        </el-option>
                        <el-option label="其他食物" :value="0"></el-option>
                    </el-option-group>
                </el-select><br>
                 <el-input class="input_class" placeholder="请添加其他疾病过敏物" v-model="o_form.allergy"
                 v-if="form.allergy.indexOf(0)!=-1 || form.allergy.indexOf(-1)!=-1" style="margin-top:10px;"></el-input>
            </el-form-item>
             <el-form-item label="医生诊断：">
                <el-select v-model="form.diagnosed" multiple filterable placeholder="请输入关键词"
                @visible-change="getChoice($event,4)" class="input_class" clearable :disabled="is_look">
                    <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.title">
                    </el-option>
                </el-select><br>
            </el-form-item>
             <el-form-item label="补充诊断：">
                <el-input class="input_class" placeholder="请填写补充诊断" type="textarea" v-model="form.supple" :rows="6" :disabled="is_look"></el-input>
            </el-form-item>
            <el-form-item label="诊断图片：">
                <el-select v-model="form.image" multiple filterable placeholder="请输入关键词"
                @visible-change="getChoice($event,5)" class="input_class" @change="handleImage" clearable>
                    <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.title" :disabled="is_look">
                    </el-option>
                </el-select>
                <div v-for="(item,index) in form_image" :key="index" class="row" style="align-items:flex-start;margin-top:10px;">
                    <div>{{item.label}}：</div>
                    <template v-if="is_look">
                        <el-image v-for="(i,i_index) in item.image" :key="i_index"
                        :src="i" :preview-src-list="item.image" class="images"></el-image>
                    </template>
                    <ReadyUploadSource
                        @getSource="getGoodsImg($event,item)"
                        @removeThis="removeImageThis($event,item)"
                        :isMany="true"
                        :manyPath="item.image"
                        :isManyMax="10"
                        :maxSize="1024 * 1024"
                        v-else
                    ></ReadyUploadSource>
                </div>
            </el-form-item>
        </el-form>
        <Preservation @preservation="preservation" v-if="!is_look"></Preservation>
    </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Preservation from '@/components/preservation'
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    components:{
        Preservation,
        ReadyUploadSource
    },
    data(){
        return {
            info:"",
            form:{
                id:1,
                type:1,
                times:1,
                needs:'',
                old:'',
                allergy:'',
                diagnosed:'',
                supple:'',
                image:[]
            },
            o_form:{
                needs:'',
                old:'',
                allergy:'',
            },
            form_image:[],
            options:[],
            is_look:false,
            is_add:1
        }
    },
    filters:{
        getDateformat(val){
            return val?getDateformat(val):'--'
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        getInfo(){
            this.$axios.post(this.$api.beauty.serveDetail, {id:this.$route.query.id}).then(res => {
                if (res.code == 0) {
                    this.info = res.result
                    this.is_look = this.$route.query.is_look*1
                    if(res.result.diagnosis.id){
                        this.is_add = 0
                        let diagnosis = res.result.diagnosis
                        let image = diagnosis.image_detail.map(item=>item.label)
                        this.form = {
                            id:diagnosis.id,
                            type:diagnosis.id,
                            times:diagnosis.times,
                            needs:diagnosis.needs.split(','),
                            old:diagnosis.old.split(','),
                            allergy:diagnosis.allergy.split(','),
                            diagnosed:diagnosis.diagnosed.split(','),
                            supple:diagnosis.supple,
                            image:image,
                        }
                        this.form_image = diagnosis.image_detail
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getChoice(query,type){
            if(query){
                this.$axios.post(this.$api.beauty.diagnosisSetList, {
                    page:1,
                    rows:100,
                    type,
                    title:''
                }).then(res => {
                    if (res.code == 0) {
                        this.options = res.result.list
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }else{
                this.options = []
            }
        },
        handleImage(val){
            let arr = []
            val.forEach(item=>{
                arr.push({
                    label:item,
                    image:[]
                })
            })
            this.form_image = arr
        },
        getGoodsImg(val,item) {
            item.image = item.image.concat(val.map(item=>item.path))
        },
        removeImageThis(i_index,item) {
            item.image.splice(i_index, 1);
        },
        preservation(){
            let obj = Object.assign({},this.form)
            obj.id = this.$route.query.id
            obj.needs = obj.needs.filter(v=>v!=0).toString()
            if(this.o_form.needs){
                obj.needs = obj.needs + (obj.needs?',':'') + this.o_form.needs
            }
            obj.old = obj.old.filter(v=>v!=0).toString()
            if(this.o_form.old){
                obj.old = obj.old + (obj.old?',':'') + this.o_form.old
            }
            obj.allergy = obj.allergy.filter(v=>v!=0 && v!=-1).toString()
            if(this.o_form.allergy){
                obj.allergy = obj.allergy + (obj.allergy?',':'') + this.o_form.allergy
            }
            obj.diagnosed = obj.diagnosed.toString()
            obj.image = this.form_image

            this.$axios.post(this.$api.beauty.diagnosisAdd,obj)
            .then(res => {
                if (res.code == 0) {
                    let msg = this.is_add?'添加成功':'编辑成功'
                    this.$message.success(msg)
                    setTimeout(()=>{
                        this.$router.go(-1)
                    },1000)
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .el-main{
        background: #fff;
        font-size: 14px;
        padding-bottom:200px;
    }
    .title{
        padding: 20px 0;
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 10px;
    }
    .row{
        display: flex;
        align-items: center;
        padding: 10px 0;
        .avatar{
            width: 70px;
            height: 70px;
            margin: 0 20px;
        }
        .m50{
            margin-right:50px;
        }
    }
    .input_class{
        width: 400px;
    }
    .images{
        width: 110px;
        height: 110px;
    }
</style>
